const data = [
    {
        "equipmentId": 2078014,
        "title": "HAMM 311 both",
        "brandId": null,
        "modelId": null,
        "askingPrice": 1020000.00,
        "gstIncluded": null,
        "finalPrice": 0.00,
        "agentId": null,
        "agentName": "Faisal",
        "agentContactNum": "",
        "commission": null,
        "equipmentType": "Compactor",
        "brandName": "HAMM",
        "modelName": "311",
        "yearOfManufacture": "2011",
        "yearOfRegistration": "",
        "hoursMeterReading": "5000",
        "custDescription": "",
        "currentLocationState": "MH",
        "currentLocationCity": "",
        "equipmentCondition": "",
        "isLoanExists": "No",
        "interestedForRent": "No",
        "vinNum": "",
        "chassisNum": "",
        "engineNum": "",
        "registartionValidate": null,
        "serialno": "",
        "vinImage": null,
        "registeredState": "",
        "ownersCount": null,
        "lotId": null,
        "inDemand": null,
        "isBlockedByYantra": "",
        "isValidInsurance": null,
        "insurance_validity": null,
        "isMachineInspected": null,
        "machineStatus": "Submitted",
        "machineCategoryIntons": null,
        "qualification": null,
        "ownerName": "",
        "ownerId": null,
        "ownerContactNum": "",
        "lastUpdateTs": null,
        "lastUpdateBy": null,
        "createdTs": "2024-09-25T06:16:54.000+00:00",
        "createdBy": "Gautam Ajith",
        "updatedTs": "2024-09-25T06:16:54.000+00:00",
        "updatedBy": null,
        "pricetoquote": 0,
        "loanexist": "0",
        "okToRent": "0",
        "processStage": null,
        "location": null,
        "customerId": null,
        "customerName": null,
        "bankName": "",
        "additionalInfoStatus": null,
        "additionalInfoProvided": null,
        "imageload": [
            {
                "imageId": 2078026,
                "equipmentId": 2078014,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/A1727245020124.jpeg",
                "imageType": "front view",
                "priority": 1,
                "tags": "",
                "comments": null
            },
            {
                "imageId": 2078027,
                "equipmentId": 2078014,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-25_at_11.43.11_AM_(1)1727245020124.jpeg",
                "imageType": "front view",
                "priority": 1,
                "tags": "",
                "comments": null
            },
            {
                "imageId": 2078028,
                "equipmentId": 2078014,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-25_at_11.43.11_AM1727245020124.jpeg",
                "imageType": "front view",
                "priority": 1,
                "tags": "",
                "comments": null
            },
            {
                "imageId": 2078029,
                "equipmentId": 2078014,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-25_at_11.43.12_AM_(1)1727245020124.jpeg",
                "imageType": "front view",
                "priority": 1,
                "tags": "",
                "comments": null
            },
            {
                "imageId": 2078030,
                "equipmentId": 2078014,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-25_at_11.43.12_AM_(2)1727245020124.jpeg",
                "imageType": "front view",
                "priority": 1,
                "tags": "",
                "comments": null
            },
            {
                "imageId": 2078031,
                "equipmentId": 2078014,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-25_at_11.43.12_AM1727245020124.jpeg",
                "imageType": "front view",
                "priority": 1,
                "tags": "",
                "comments": null
            },
            {
                "imageId": 2078032,
                "equipmentId": 2078014,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-25_at_11.43.13_AM_(1)1727245020124.jpeg",
                "imageType": "front view",
                "priority": 1,
                "tags": "",
                "comments": null
            },
            {
                "imageId": 2078033,
                "equipmentId": 2078014,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-25_at_11.43.13_AM_(2)1727245020124.jpeg",
                "imageType": "front view",
                "priority": 1,
                "tags": "",
                "comments": null
            },
            {
                "imageId": 2078034,
                "equipmentId": 2078014,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-25_at_11.43.13_AM1727245020124.jpeg",
                "imageType": "front view",
                "priority": 1,
                "tags": "",
                "comments": null
            },
            {
                "imageId": 2078035,
                "equipmentId": 2078014,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-25_at_11.43.14_AM1727245020124.jpeg",
                "imageType": "front view",
                "priority": 1,
                "tags": "",
                "comments": null
            }
        ],
        "totalCount": 1060,
        "classification": null,
        "comments": null,
        "readyToSellStatus": "",
        "salesStatus": "",
        "salesStatusReason": "",
        "woocommerId": null,
        "woocomStatus": null,
        "websiteLink": null,
        "assetOwnership": "",
        "assetAssignment": "",
        "usedEquipmentFeatures": [],
        "usedEquipmentAttachments": [],
        "zohoItemId": "409173000067352009",
        "quotationStatus": null,
        "quotationPrice": null,
        "quotationId": null,
        "customerTargetPrice": null,
        "buyQuotationStatus": null,
        "buyQuotationPrice": null,
        "buyQuotationId": null,
        "buyAskingPrice": null,
        "sku": "EAI2078014",
        "source": "web",
        "usedOrNew": false,
        "gstType": null,
        "sellingPrice": null,
        "usedEquipmentPriority": null,
        "condition": "",
        "availablilty": "",
        "fuelType": "",
        "insurance": "",
        "inspection": "",
        "sellTerritory": [
            "IND","UAE"
        ],
        
        "specificationsSummary": [
      "Engine: JCB Dieselmax 444",
      "Net Power: Approximately 74 kW (100 hp)",
      "Maximum Lift Height: 11 meters",
      "Maximum Lift Capacity: 3,000 kg",
      "Hydraulic Flow: 110 l/min",
      "Operating Weight: 7,300 kg",
    ],
        "specifications":null,
        "overview": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        "performanceCapabilities": 'The 530-110 Agri combines power, efficiency, and versatility, making it suitable for a wide range of tasks. Its robust build ensures durability even in demanding environments, while its precise hydraulic system allows smooth control for efficient material handling. This telehandler is designed to optimize productivity and reduce operational costs.',
        "noOfPastOwners": null,
        "timeToSell": ""
    },
    {
        "equipmentId": 2077436,
        "title": "CATERPILLAR 330GC",
        "brandId": null,
        "modelId": null,
        "usedDays": null,
        "askingPrice": 1.00,
        "gstIncluded": null,
        "finalPrice": 0.00,
        "agentId": null,
        "agentName": "Chethan",
        "agentContactNum": "",
        "commission": null,
        "equipmentType": "Excavator",
        "brandName": "CATERPILLAR",
        "modelName": "330GC",
        "yearOfManufacture": "2023",
        "yearOfRegistration": "",
        "hoursMeterReading": "4000",
        "custDescription": "",
        "currentLocationState": "RJ",
        "currentLocationCity": "Bikaner",
        "equipmentCondition": "",
        "isLoanExists": "No",
        "interestedForRent": "Yes",
        "vinNum": "",
        "chassisNum": "",
        "engineNum": "",
        "registartionValidate": null,
        "serialno": "",
        "vinImage": null,
        "registeredState": "",
        "ownersCount": null,
        "lotId": null,
        "inDemand": null,
        "isBlockedByYantra": "",
        "isValidInsurance": null,
        "insurance_validity": null,
        "isMachineInspected": null,
        "machineStatus": "Submitted",
        "machineCategoryIntons": null,
        "qualification": null,
        "ownerName": "",
        "ownerId": null,
        "ownerContactNum": "",
        "lastUpdateTs": null,
        "lastUpdateBy": null,
        "createdTs": "2024-09-24T12:44:00.000+00:00",
        "createdBy": "Rukmini SM",
        "updatedTs": "2024-09-24T12:45:00.000+00:00",
        "updatedBy": "Rukmini SM",
        "pricetoquote": 0,
        "loanexist": "0",
        "okToRent": "0",
        "processStage": null,
        "location": null,
        "customerId": null,
        "customerName": null,
        "bankName": "",
        "additionalInfoStatus": "REQUESTED",
        "additionalInfoProvided": null,
        "imageload": [
            {
                "imageId": 2077451,
                "equipmentId": 2077436,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-13_at_5.33.44_PM1727181846546.jpeg",
                "imageType": "front view",
                "priority": -1,
                "tags": "Hero Image",
                "comments": null
            },
            {
                "imageId": 2077448,
                "equipmentId": 2077436,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-13_at_5.33.42_PM1727181846546.jpeg",
                "imageType": "front view",
                "priority": 0,
                "tags": "Left View",
                "comments": null
            },
            {
                "imageId": 2077449,
                "equipmentId": 2077436,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-13_at_5.33.43_PM_(1)1727181846546.jpeg",
                "imageType": "front view",
                "priority": 1,
                "tags": "Front View",
                "comments": null
            },
            {
                "imageId": 2077450,
                "equipmentId": 2077436,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-13_at_5.33.43_PM1727181846546.jpeg",
                "imageType": "front view",
                "priority": 2,
                "tags": "HourMeter",
                "comments": null
            }
        ],
        "totalCount": 1060,
        "classification": null,
        "comments": null,
        "readyToSellStatus": "",
        "salesStatus": "",
        "salesStatusReason": "",
        "woocommerId": null,
        "woocomStatus": null,
        "websiteLink": null,
        "assetOwnership": "",
        "assetAssignment": "",
        "usedEquipmentFeatures": [],
        "usedEquipmentAttachments": [],
        "zohoItemId": null,
        "quotationStatus": null,
        "quotationPrice": null,
        "quotationId": null,
        "customerTargetPrice": null,
        "buyQuotationStatus": null,
        "buyQuotationPrice": null,
        "buyQuotationId": null,
        "buyAskingPrice": null,
        "sku": null,
        "source": "web",
        "usedOrNew": false,
        "gstType": null,
        "sellingPrice": null,
        "usedEquipmentPriority": null,
        "condition": "",
        "availablilty": "",
        "fuelType": "",
        "insurance": "",
        "inspection": "",
        "sellTerritory": [
            "IND"
        ],
        "specificationsSummary": [
      "Engine: JCB Dieselmax 444",
      "Net Power: Approximately 74 kW (100 hp)",
      "Maximum Lift Height: 11 meters",
      "Maximum Lift Capacity: 3,000 kg",
      "Hydraulic Flow: 110 l/min",
      "Operating Weight: 7,300 kg",
    ],
        "specifications":null,
        "overview": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        "performanceCapabilities": 'The 530-110 Agri combines power, efficiency, and versatility, making it suitable for a wide range of tasks. Its robust build ensures durability even in demanding environments, while its precise hydraulic system allows smooth control for efficient material handling. This telehandler is designed to optimize productivity and reduce operational costs.',
        "noOfPastOwners": null,
        "timeToSell": ""
    },
    {
        "equipmentId": 2075559,
        "title": "L&T KOMATSU PC350LC-7",
        "brandId": null,
        "modelId": null,
        "usedDays": null,
        "askingPrice": 3000000.00,
        "gstIncluded": null,
        "finalPrice": 0.00,
        "agentId": null,
        "agentName": "Chethan",
        "agentContactNum": "",
        "commission": null,
        "equipmentType": "Excavator",
        "brandName": "L&T KOMATSU",
        "modelName": "PC350LC-7",
        "yearOfManufacture": "2018",
        "yearOfRegistration": "",
        "hoursMeterReading": "14500",
        "custDescription": "",
        "currentLocationState": "AD",
        "currentLocationCity": "",
        "equipmentCondition": "",
        "isLoanExists": "No",
        "interestedForRent": "Yes",
        "vinNum": "KMTPC070AJY350151",
        "chassisNum": "",
        "engineNum": "",
        "registartionValidate": null,
        "serialno": "KMTPC070AJY350151",
        "vinImage": null,
        "registeredState": "",
        "ownersCount": null,
        "lotId": null,
        "inDemand": null,
        "isBlockedByYantra": "",
        "isValidInsurance": null,
        "insurance_validity": null,
        "isMachineInspected": null,
        "machineStatus": "Submitted",
        "machineCategoryIntons": null,
        "qualification": null,
        "ownerName": "",
        "ownerId": null,
        "ownerContactNum": "",
        "lastUpdateTs": null,
        "lastUpdateBy": null,
        "createdTs": "2024-09-23T05:23:51.000+00:00",
        "createdBy": "undefined undefined",
        "updatedTs": "2024-09-23T05:25:12.000+00:00",
        "updatedBy": "undefined undefined",
        "pricetoquote": 0,
        "loanexist": "0",
        "okToRent": "0",
        "processStage": null,
        "location": null,
        "customerId": null,
        "customerName": null,
        "bankName": "",
        "additionalInfoStatus": null,
        "additionalInfoProvided": null,
        "imageload": [
            {
                "imageId": 2075571,
                "equipmentId": 2075559,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-23_at_10.26.11_AM_(1)1727069036967.jpeg",
                "imageType": "front view",
                "priority": 0,
                "tags": "Right View",
                "comments": null
            },
            {
                "imageId": 2075572,
                "equipmentId": 2075559,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-23_at_10.26.11_AM1727069036967.jpeg",
                "imageType": "front view",
                "priority": 1,
                "tags": "Right View",
                "comments": null
            },
            {
                "imageId": 2075573,
                "equipmentId": 2075559,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-23_at_10.26.12_AM_(1)1727069036967.jpeg",
                "imageType": "front view",
                "priority": 2,
                "tags": "Left View",
                "comments": null
            },
            {
                "imageId": 2075574,
                "equipmentId": 2075559,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-23_at_10.26.12_AM1727069036967.jpeg",
                "imageType": "front view",
                "priority": 3,
                "tags": "Left View",
                "comments": null
            },
            {
                "imageId": 2075575,
                "equipmentId": 2075559,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-23_at_10.26.13_AM_(1)1727069036967.jpeg",
                "imageType": "front view",
                "priority": 4,
                "tags": "VIN",
                "comments": null
            },
            {
                "imageId": 2075576,
                "equipmentId": 2075559,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-23_at_10.26.13_AM_(2)1727069036967.jpeg",
                "imageType": "front view",
                "priority": 5,
                "tags": "Cabin",
                "comments": null
            },
            {
                "imageId": 2075577,
                "equipmentId": 2075559,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-23_at_10.26.13_AM1727069036967.jpeg",
                "imageType": "front view",
                "priority": 6,
                "tags": "Front View",
                "comments": null
            },
            {
                "imageId": 2075578,
                "equipmentId": 2075559,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-23_at_10.26.14_AM1727069036967.jpeg",
                "imageType": "front view",
                "priority": 7,
                "tags": "HourMeter",
                "comments": null
            }
        ],
        "totalCount": 1060,
        "classification": null,
        "comments": null,
        "readyToSellStatus": "",
        "salesStatus": "",
        "salesStatusReason": "",
        "woocommerId": null,
        "woocomStatus": null,
        "websiteLink": null,
        "assetOwnership": "",
        "assetAssignment": "",
        "usedEquipmentFeatures": [],
        "usedEquipmentAttachments": [],
        "zohoItemId": "409173000067256013",
        "quotationStatus": null,
        "quotationPrice": null,
        "quotationId": null,
        "customerTargetPrice": null,
        "buyQuotationStatus": null,
        "buyQuotationPrice": null,
        "buyQuotationId": null,
        "buyAskingPrice": null,
        "sku": "EAI2075559",
        "source": "web",
        "usedOrNew": false,
        "gstType": null,
        "sellingPrice": null,
        "usedEquipmentPriority": null,
        "condition": "",
        "availablilty": "",
        "fuelType": "",
        "insurance": "",
        "inspection": "",
        "sellTerritory": [
            "IND"
        ],
        "specificationsSummary": [
      "Engine: JCB Dieselmax 444",
      "Net Power: Approximately 74 kW (100 hp)",
      "Maximum Lift Height: 11 meters",
      "Maximum Lift Capacity: 3,000 kg",
      "Hydraulic Flow: 110 l/min",
      "Operating Weight: 7,300 kg",
    ],
        "specifications":null,
        "overview": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        "performanceCapabilities": 'The 530-110 Agri combines power, efficiency, and versatility, making it suitable for a wide range of tasks. Its robust build ensures durability even in demanding environments, while its precise hydraulic system allows smooth control for efficient material handling. This telehandler is designed to optimize productivity and reduce operational costs.',
        "noOfPastOwners": null,
        "timeToSell": ""
    },
    {
        "equipmentId": 2075380,
        "title": "BOBCAT S750 2008 10,000 hrs",
        "brandId": null,
        "modelId": null,
        "usedDays": null,
        "askingPrice": 700000.00,
        "gstIncluded": null,
        "finalPrice": null,
        "agentId": null,
        "agentName": null,
        "agentContactNum": null,
        "commission": null,
        "equipmentType": "Skid Loader",
        "brandName": "BOBCAT",
        "modelName": "S750",
        "yearOfManufacture": "2008",
        "yearOfRegistration": null,
        "hoursMeterReading": "10,000",
        "custDescription": null,
        "currentLocationState": null,
        "currentLocationCity": null,
        "equipmentCondition": null,
        "isLoanExists": null,
        "interestedForRent": null,
        "vinNum": null,
        "chassisNum": null,
        "engineNum": null,
        "registartionValidate": null,
        "serialno": null,
        "vinImage": null,
        "registeredState": null,
        "ownersCount": null,
        "lotId": null,
        "inDemand": null,
        "isBlockedByYantra": null,
        "isValidInsurance": null,
        "insurance_validity": null,
        "isMachineInspected": null,
        "machineStatus": "Submitted",
        "machineCategoryIntons": null,
        "qualification": null,
        "ownerName": null,
        "ownerId": null,
        "ownerContactNum": null,
        "lastUpdateTs": null,
        "lastUpdateBy": null,
        "createdTs": "2024-09-20T12:20:25.000+00:00",
        "createdBy": null,
        "updatedTs": "2024-09-20T12:20:25.000+00:00",
        "updatedBy": null,
        "pricetoquote": null,
        "loanexist": null,
        "okToRent": null,
        "processStage": null,
        "location": "Pune, Maharashtra, India",
        "customerId": 70861,
        "customerName": null,
        "bankName": null,
        "additionalInfoStatus": null,
        "additionalInfoProvided": null,
        "imageload": null,
        "totalCount": 1060,
        "classification": null,
        "comments": null,
        "readyToSellStatus": "NONE",
        "salesStatus": null,
        "salesStatusReason": null,
        "woocommerId": null,
        "woocomStatus": null,
        "websiteLink": null,
        "assetOwnership": null,
        "assetAssignment": null,
        "usedEquipmentFeatures": [],
        "usedEquipmentAttachments": [],
        "zohoItemId": null,
        "quotationStatus": null,
        "quotationPrice": null,
        "quotationId": null,
        "customerTargetPrice": null,
        "buyQuotationStatus": null,
        "buyQuotationPrice": null,
        "buyQuotationId": null,
        "buyAskingPrice": null,
        "sku": null,
        "source": "mobile",
        "usedOrNew": null,
        "gstType": null,
        "sellingPrice": null,
        "usedEquipmentPriority": null,
        "condition": null,
        "availablilty": null,
        "fuelType": null,
        "insurance": null,
        "inspection": null,
        "sellTerritory": [
            "UAE"
        ],
        "specificationsSummary": [
      "Engine: JCB Dieselmax 444",
      "Net Power: Approximately 74 kW (100 hp)",
      "Maximum Lift Height: 11 meters",
      "Maximum Lift Capacity: 3,000 kg",
      "Hydraulic Flow: 110 l/min",
      "Operating Weight: 7,300 kg",
    ],
        "specifications":null,
        "overview": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        "performanceCapabilities": 'The 530-110 Agri combines power, efficiency, and versatility, making it suitable for a wide range of tasks. Its robust build ensures durability even in demanding environments, while its precise hydraulic system allows smooth control for efficient material handling. This telehandler is designed to optimize productivity and reduce operational costs.',
        "noOfPastOwners": null,
        "timeToSell": "",
        "imageload": [
            {
                "imageId": 2075571,
                "equipmentId": 2075559,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-23_at_10.26.11_AM_(1)1727069036967.jpeg",
                "imageType": "front view",
                "priority": 0,
                "tags": "Right View",
                "comments": null
            },
            {
                "imageId": 2075572,
                "equipmentId": 2075559,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-23_at_10.26.11_AM1727069036967.jpeg",
                "imageType": "front view",
                "priority": 1,
                "tags": "Right View",
                "comments": null
            },
            {
                "imageId": 2075573,
                "equipmentId": 2075559,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-23_at_10.26.12_AM_(1)1727069036967.jpeg",
                "imageType": "front view",
                "priority": 2,
                "tags": "Left View",
                "comments": null
            },]
    },
    {
        "equipmentId": 2075377,
        "title": "BOBCAT S750 2008 10,000 hrs",
        "brandId": null,
        "modelId": null,
        "usedDays": null,
        "askingPrice": 700000.00,
        "gstIncluded": null,
        "finalPrice": null,
        "agentId": null,
        "agentName": null,
        "agentContactNum": null,
        "commission": null,
        "equipmentType": "Skid Loader",
        "brandName": "BOBCAT",
        "modelName": "S750",
        "yearOfManufacture": "2008",
        "yearOfRegistration": null,
        "hoursMeterReading": "10,000",
        "custDescription": null,
        "currentLocationState": null,
        "currentLocationCity": null,
        "equipmentCondition": null,
        "isLoanExists": null,
        "interestedForRent": null,
        "vinNum": null,
        "chassisNum": null,
        "engineNum": null,
        "registartionValidate": null,
        "serialno": null,
        "vinImage": null,
        "registeredState": null,
        "ownersCount": null,
        "lotId": null,
        "inDemand": null,
        "isBlockedByYantra": null,
        "isValidInsurance": null,
        "insurance_validity": null,
        "isMachineInspected": null,
        "machineStatus": "Submitted",
        "machineCategoryIntons": null,
        "qualification": null,
        "ownerName": null,
        "ownerId": null,
        "ownerContactNum": null,
        "lastUpdateTs": null,
        "lastUpdateBy": null,
        "createdTs": "2024-09-20T12:20:22.000+00:00",
        "createdBy": null,
        "updatedTs": "2024-09-20T12:20:22.000+00:00",
        "updatedBy": null,
        "pricetoquote": null,
        "loanexist": null,
        "okToRent": null,
        "processStage": null,
        "location": "Pune, Maharashtra, India",
        "customerId": 70861,
        "customerName": null,
        "bankName": null,
        "additionalInfoStatus": null,
        "additionalInfoProvided": null,
        "imageload": null,
        "totalCount": 1060,
        "classification": null,
        "comments": null,
        "readyToSellStatus": "NONE",
        "salesStatus": null,
        "salesStatusReason": null,
        "woocommerId": null,
        "woocomStatus": null,
        "websiteLink": null,
        "assetOwnership": null,
        "assetAssignment": null,
        "usedEquipmentFeatures": [],
        "usedEquipmentAttachments": [],
        "zohoItemId": null,
        "quotationStatus": null,
        "quotationPrice": null,
        "quotationId": null,
        "customerTargetPrice": null,
        "buyQuotationStatus": null,
        "buyQuotationPrice": null,
        "buyQuotationId": null,
        "buyAskingPrice": null,
        "sku": null,
        "source": "mobile",
        "usedOrNew": null,
        "gstType": null,
        "sellingPrice": null,
        "usedEquipmentPriority": null,
        "condition": null,
        "availablilty": null,
        "fuelType": null,
        "insurance": null,
        "inspection": null,
        "sellTerritory": [
            "UAE"
        ],
       "specificationsSummary": [
      "Engine: JCB Dieselmax 444",
      "Net Power: Approximately 74 kW (100 hp)",
      "Maximum Lift Height: 11 meters",
      "Maximum Lift Capacity: 3,000 kg",
      "Hydraulic Flow: 110 l/min",
      "Operating Weight: 7,300 kg",
    ],
        "specifications":null,
        "overview": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        "performanceCapabilities": 'The 530-110 Agri combines power, efficiency, and versatility, making it suitable for a wide range of tasks. Its robust build ensures durability even in demanding environments, while its precise hydraulic system allows smooth control for efficient material handling. This telehandler is designed to optimize productivity and reduce operational costs.',
        "noOfPastOwners": null,
        "timeToSell": "",
        "imageload": [
            {
                "imageId": 2075571,
                "equipmentId": 2075559,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-23_at_10.26.11_AM_(1)1727069036967.jpeg",
                "imageType": "front view",
                "priority": 0,
                "tags": "Right View",
                "comments": null
            },
            {
                "imageId": 2075572,
                "equipmentId": 2075559,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-23_at_10.26.11_AM1727069036967.jpeg",
                "imageType": "front view",
                "priority": 1,
                "tags": "Right View",
                "comments": null
            },
            {
                "imageId": 2075573,
                "equipmentId": 2075559,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-23_at_10.26.12_AM_(1)1727069036967.jpeg",
                "imageType": "front view",
                "priority": 2,
                "tags": "Left View",
                "comments": null
            },
        ]
    },
    {
        "equipmentId": 2075379,
        "title": "BOBCAT S750 2008 10,000 hrs",
        "brandId": null,
        "modelId": null,
        "usedDays": null,
        "askingPrice": 700000.00,
        "gstIncluded": null,
        "finalPrice": null,
        "agentId": null,
        "agentName": null,
        "agentContactNum": null,
        "commission": null,
        "equipmentType": "Skid Loader",
        "brandName": "BOBCAT",
        "modelName": "S750",
        "yearOfManufacture": "2008",
        "yearOfRegistration": null,
        "hoursMeterReading": "10,000",
        "custDescription": null,
        "currentLocationState": null,
        "currentLocationCity": null,
        "equipmentCondition": null,
        "isLoanExists": null,
        "interestedForRent": null,
        "vinNum": null,
        "chassisNum": null,
        "engineNum": null,
        "registartionValidate": null,
        "serialno": null,
        "vinImage": null,
        "registeredState": null,
        "ownersCount": null,
        "lotId": null,
        "inDemand": null,
        "isBlockedByYantra": null,
        "isValidInsurance": null,
        "insurance_validity": null,
        "isMachineInspected": null,
        "machineStatus": "Submitted",
        "machineCategoryIntons": null,
        "qualification": null,
        "ownerName": null,
        "ownerId": null,
        "ownerContactNum": null,
        "lastUpdateTs": null,
        "lastUpdateBy": null,
        "createdTs": "2024-09-20T12:20:22.000+00:00",
        "createdBy": null,
        "updatedTs": "2024-09-20T12:20:22.000+00:00",
        "updatedBy": null,
        "pricetoquote": null,
        "loanexist": null,
        "okToRent": null,
        "processStage": null,
        "location": "Pune, Maharashtra, India",
        "customerId": 70861,
        "customerName": null,
        "bankName": null,
        "additionalInfoStatus": null,
        "additionalInfoProvided": null,
        "imageload": null,
        "totalCount": 1060,
        "classification": null,
        "comments": null,
        "readyToSellStatus": "NONE",
        "salesStatus": null,
        "salesStatusReason": null,
        "woocommerId": null,
        "woocomStatus": null,
        "websiteLink": null,
        "assetOwnership": null,
        "assetAssignment": null,
        "usedEquipmentFeatures": [],
        "usedEquipmentAttachments": [],
        "zohoItemId": null,
        "quotationStatus": null,
        "quotationPrice": null,
        "quotationId": null,
        "customerTargetPrice": null,
        "buyQuotationStatus": null,
        "buyQuotationPrice": null,
        "buyQuotationId": null,
        "buyAskingPrice": null,
        "sku": null,
        "source": "mobile",
        "usedOrNew": null,
        "gstType": null,
        "sellingPrice": null,
        "usedEquipmentPriority": null,
        "condition": null,
        "availablilty": null,
        "fuelType": null,
        "insurance": null,
        "inspection": null,
        "sellTerritory": [
            "UAE"
        ],
       "specificationsSummary": [
      "Engine: JCB Dieselmax 444",
      "Net Power: Approximately 74 kW (100 hp)",
      "Maximum Lift Height: 11 meters",
      "Maximum Lift Capacity: 3,000 kg",
      "Hydraulic Flow: 110 l/min",
      "Operating Weight: 7,300 kg",
    ],
        "specifications":null,
        "overview": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        "performanceCapabilities": 'The 530-110 Agri combines power, efficiency, and versatility, making it suitable for a wide range of tasks. Its robust build ensures durability even in demanding environments, while its precise hydraulic system allows smooth control for efficient material handling. This telehandler is designed to optimize productivity and reduce operational costs.',
        "noOfPastOwners": null,
        "timeToSell": null,
        "imageload": [
            {
                "imageId": 2075571,
                "equipmentId": 2075559,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-23_at_10.26.11_AM_(1)1727069036967.jpeg",
                "imageType": "front view",
                "priority": 0,
                "tags": "Right View",
                "comments": null
            },
            {
                "imageId": 2075572,
                "equipmentId": 2075559,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-23_at_10.26.11_AM1727069036967.jpeg",
                "imageType": "front view",
                "priority": 1,
                "tags": "Right View",
                "comments": null
            },
            {
                "imageId": 2075573,
                "equipmentId": 2075559,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-23_at_10.26.12_AM_(1)1727069036967.jpeg",
                "imageType": "front view",
                "priority": 2,
                "tags": "Left View",
                "comments": null
            },
        ]
    },
    {
        "equipmentId": 2075378,
        "title": "BOBCAT S750 2008 10,000 hrs",
        "brandId": null,
        "modelId": null,
        "usedDays": null,
        "askingPrice": 700000.00,
        "gstIncluded": null,
        "finalPrice": null,
        "agentId": null,
        "agentName": null,
        "agentContactNum": null,
        "commission": null,
        "equipmentType": "Skid Loader",
        "brandName": "BOBCAT",
        "modelName": "S750",
        "yearOfManufacture": "2008",
        "yearOfRegistration": null,
        "hoursMeterReading": "10,000",
        "custDescription": null,
        "currentLocationState": null,
        "currentLocationCity": null,
        "equipmentCondition": null,
        "isLoanExists": null,
        "interestedForRent": null,
        "vinNum": null,
        "chassisNum": null,
        "engineNum": null,
        "registartionValidate": null,
        "serialno": null,
        "vinImage": null,
        "registeredState": null,
        "ownersCount": null,
        "lotId": null,
        "inDemand": null,
        "isBlockedByYantra": null,
        "isValidInsurance": null,
        "insurance_validity": null,
        "isMachineInspected": null,
        "machineStatus": "Submitted",
        "machineCategoryIntons": null,
        "qualification": null,
        "ownerName": null,
        "ownerId": null,
        "ownerContactNum": null,
        "lastUpdateTs": null,
        "lastUpdateBy": null,
        "createdTs": "2024-09-20T12:20:22.000+00:00",
        "createdBy": null,
        "updatedTs": "2024-09-20T12:20:22.000+00:00",
        "updatedBy": null,
        "pricetoquote": null,
        "loanexist": null,
        "okToRent": null,
        "processStage": null,
        "location": "Pune, Maharashtra, India",
        "customerId": 70861,
        "customerName": null,
        "bankName": null,
        "additionalInfoStatus": null,
        "additionalInfoProvided": null,
        "imageload": null,
        "totalCount": 1060,
        "classification": null,
        "comments": null,
        "readyToSellStatus": "NONE",
        "salesStatus": null,
        "salesStatusReason": null,
        "woocommerId": null,
        "woocomStatus": null,
        "websiteLink": null,
        "assetOwnership": null,
        "assetAssignment": null,
        "usedEquipmentFeatures": [],
        "usedEquipmentAttachments": [],
        "zohoItemId": null,
        "quotationStatus": null,
        "quotationPrice": null,
        "quotationId": null,
        "customerTargetPrice": null,
        "buyQuotationStatus": null,
        "buyQuotationPrice": null,
        "buyQuotationId": null,
        "buyAskingPrice": null,
        "sku": null,
        "source": "mobile",
        "usedOrNew": null,
        "gstType": null,
        "sellingPrice": null,
        "usedEquipmentPriority": null,
        "condition": null,
        "availablilty": null,
        "fuelType": null,
        "insurance": null,
        "inspection": null,
        "sellTerritory": [
            "UAE"
        ],
        "specificationsSummary": [
      "Engine: JCB Dieselmax 444",
      "Net Power: Approximately 74 kW (100 hp)",
      "Maximum Lift Height: 11 meters",
      "Maximum Lift Capacity: 3,000 kg",
      "Hydraulic Flow: 110 l/min",
      "Operating Weight: 7,300 kg",
    ],
        "specifications":null,
        "overview": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        "performanceCapabilities": 'The 530-110 Agri combines power, efficiency, and versatility, making it suitable for a wide range of tasks. Its robust build ensures durability even in demanding environments, while its precise hydraulic system allows smooth control for efficient material handling. This telehandler is designed to optimize productivity and reduce operational costs.',
        "noOfPastOwners": null,
        "timeToSell":null,
        "imageload": [
            {
                "imageId": 2075571,
                "equipmentId": 2075559,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-23_at_10.26.11_AM_(1)1727069036967.jpeg",
                "imageType": "front view",
                "priority": 0,
                "tags": "Right View",
                "comments": null
            },
            {
                "imageId": 2075572,
                "equipmentId": 2075559,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-23_at_10.26.11_AM1727069036967.jpeg",
                "imageType": "front view",
                "priority": 1,
                "tags": "Right View",
                "comments": null
            },
            {
                "imageId": 2075573,
                "equipmentId": 2075559,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-23_at_10.26.12_AM_(1)1727069036967.jpeg",
                "imageType": "front view",
                "priority": 2,
                "tags": "Left View",
                "comments": null
            },]
    },
    {
        "equipmentId": 2075376,
        "title": "BOBCAT S750 2008 10,000 hrs",
        "brandId": null,
        "modelId": null,
        "usedDays": null,
        "askingPrice": 700000.00,
        "gstIncluded": null,
        "finalPrice": null,
        "agentId": null,
        "agentName": null,
        "agentContactNum": null,
        "commission": null,
        "equipmentType": "Skid Loader",
        "brandName": "BOBCAT",
        "modelName": "S750",
        "yearOfManufacture": "2008",
        "yearOfRegistration": null,
        "hoursMeterReading": "10,000",
        "custDescription": null,
        "currentLocationState": null,
        "currentLocationCity": null,
        "equipmentCondition": null,
        "isLoanExists": null,
        "interestedForRent": null,
        "vinNum": null,
        "chassisNum": null,
        "engineNum": null,
        "registartionValidate": null,
        "serialno": null,
        "vinImage": null,
        "registeredState": null,
        "ownersCount": null,
        "lotId": null,
        "inDemand": null,
        "isBlockedByYantra": null,
        "isValidInsurance": null,
        "insurance_validity": null,
        "isMachineInspected": null,
        "machineStatus": "Submitted",
        "machineCategoryIntons": null,
        "qualification": null,
        "ownerName": null,
        "ownerId": null,
        "ownerContactNum": null,
        "lastUpdateTs": null,
        "lastUpdateBy": null,
        "createdTs": "2024-09-20T12:19:55.000+00:00",
        "createdBy": null,
        "updatedTs": "2024-09-20T12:19:55.000+00:00",
        "updatedBy": null,
        "pricetoquote": null,
        "loanexist": null,
        "okToRent": null,
        "processStage": null,
        "location": "Pune, Maharashtra, India",
        "customerId": 70861,
        "customerName": null,
        "bankName": null,
        "additionalInfoStatus": null,
        "additionalInfoProvided": null,
        "imageload": null,
        "totalCount": 1060,
        "classification": null,
        "comments": null,
        "readyToSellStatus": "NONE",
        "salesStatus": null,
        "salesStatusReason": null,
        "woocommerId": null,
        "woocomStatus": null,
        "websiteLink": null,
        "assetOwnership": null,
        "assetAssignment": null,
        "usedEquipmentFeatures": [],
        "usedEquipmentAttachments": [],
        "zohoItemId": null,
        "quotationStatus": null,
        "quotationPrice": null,
        "quotationId": null,
        "customerTargetPrice": null,
        "buyQuotationStatus": null,
        "buyQuotationPrice": null,
        "buyQuotationId": null,
        "buyAskingPrice": null,
        "sku": null,
        "source": "mobile",
        "usedOrNew": null,
        "gstType": null,
        "sellingPrice": null,
        "usedEquipmentPriority": null,
        "condition": null,
        "availablilty": null,
        "fuelType": null,
        "insurance": null,
        "inspection": null,
        "sellTerritory": [
            "UAE"
        ],
      "specificationsSummary": [
      "Engine: JCB Dieselmax 444",
      "Net Power: Approximately 74 kW (100 hp)",
      "Maximum Lift Height: 11 meters",
      "Maximum Lift Capacity: 3,000 kg",
      "Hydraulic Flow: 110 l/min",
      "Operating Weight: 7,300 kg",
    ],
        "specifications":null,
        "overview": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        "performanceCapabilities": 'The 530-110 Agri combines power, efficiency, and versatility, making it suitable for a wide range of tasks. Its robust build ensures durability even in demanding environments, while its precise hydraulic system allows smooth control for efficient material handling. This telehandler is designed to optimize productivity and reduce operational costs.',
        "noOfPastOwners": null,
        "timeToSell": null,
        "imageload": [
            {
                "imageId": 2075571,
                "equipmentId": 2075559,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-23_at_10.26.11_AM_(1)1727069036967.jpeg",
                "imageType": "front view",
                "priority": 0,
                "tags": "Right View",
                "comments": null
            },
            {
                "imageId": 2075572,
                "equipmentId": 2075559,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-23_at_10.26.11_AM1727069036967.jpeg",
                "imageType": "front view",
                "priority": 1,
                "tags": "Right View",
                "comments": null
            },
            {
                "imageId": 2075573,
                "equipmentId": 2075559,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-23_at_10.26.12_AM_(1)1727069036967.jpeg",
                "imageType": "front view",
                "priority": 2,
                "tags": "Left View",
                "comments": null
            },]
    },
    {
        "equipmentId": 2058939,
        "title": "HAMM 311D",
        "brandId": null,
        "modelId": null,
        "usedDays": null,
        "askingPrice": 1850000.00,
        "gstIncluded": null,
        "finalPrice": 0.00,
        "agentId": null,
        "agentName": "Nathiya",
        "agentContactNum": "",
        "commission": null,
        "equipmentType": "",
        "brandName": "HAMM",
        "modelName": "311D",
        "yearOfManufacture": "2018",
        "yearOfRegistration": "",
        "hoursMeterReading": "2277",
        "custDescription": "",
        "currentLocationState": "AS",
        "currentLocationCity": "",
        "equipmentCondition": "",
        "isLoanExists": "No",
        "interestedForRent": "Yes",
        "vinNum": "",
        "chassisNum": "",
        "engineNum": "",
        "registartionValidate": null,
        "serialno": "",
        "vinImage": null,
        "registeredState": "",
        "ownersCount": null,
        "lotId": null,
        "inDemand": null,
        "isBlockedByYantra": "",
        "isValidInsurance": null,
        "insurance_validity": null,
        "isMachineInspected": null,
        "machineStatus": "Submitted",
        "machineCategoryIntons": null,
        "qualification": null,
        "ownerName": "",
        "ownerId": null,
        "ownerContactNum": "",
        "lastUpdateTs": null,
        "lastUpdateBy": null,
        "createdTs": "2024-09-05T07:06:22.000+00:00",
        "createdBy": "Rukmini SM",
        "updatedTs": "2024-09-20T09:36:37.000+00:00",
        "updatedBy": "undefined undefined",
        "pricetoquote": 0,
        "loanexist": "0",
        "okToRent": "0",
        "processStage": null,
        "location": null,
        "customerId": null,
        "customerName": null,
        "bankName": "",
        "additionalInfoStatus": null,
        "additionalInfoProvided": null,
        "imageload": [
            {
                "imageId": 2058952,
                "equipmentId": 2058939,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-03_at_4.31.55_PM1725519989030.jpeg",
                "imageType": "front view",
                "priority": 0,
                "tags": "Right View",
                "comments": null
            },
            {
                "imageId": 2058953,
                "equipmentId": 2058939,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-03_at_4.31.56_PM_(1)1725519989030.jpeg",
                "imageType": "front view",
                "priority": 1,
                "tags": "Back View",
                "comments": null
            },
            {
                "imageId": 2058954,
                "equipmentId": 2058939,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-03_at_4.31.56_PM1725519989030.jpeg",
                "imageType": "front view",
                "priority": 2,
                "tags": "Left View",
                "comments": null
            },
            {
                "imageId": 2058951,
                "equipmentId": 2058939,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-03_at_4.32.14_PM1725519989030.jpeg",
                "imageType": "front view",
                "priority": 3,
                "tags": "HourMeter",
                "comments": null
            },
            {
                "imageId": 2058955,
                "equipmentId": 2058939,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-03_at_4.31.57_PM1725519989030.jpeg",
                "imageType": "front view",
                "priority": 4,
                "tags": "Front View",
                "comments": null
            }
        ],
        "totalCount": 1060,
        "classification": null,
        "comments": null,
        "readyToSellStatus": "",
        "salesStatus": "",
        "salesStatusReason": "",
        "woocommerId": null,
        "woocomStatus": null,
        "websiteLink": null,
        "assetOwnership": "",
        "assetAssignment": "",
        "usedEquipmentFeatures": [],
        "usedEquipmentAttachments": [],
        "zohoItemId": "409173000066240016",
        "quotationStatus": null,
        "quotationPrice": null,
        "quotationId": null,
        "customerTargetPrice": null,
        "buyQuotationStatus": null,
        "buyQuotationPrice": null,
        "buyQuotationId": null,
        "buyAskingPrice": null,
        "sku": "EAI2058939",
        "source": "web",
        "usedOrNew": false,
        "gstType": null,
        "sellingPrice": null,
        "usedEquipmentPriority": null,
        "condition": "",
        "availablilty": "",
        "fuelType": "",
        "insurance": "",
        "inspection": "",
        "sellTerritory": [
            "IND"
        ],
        "specificationsSummary": [
      "Engine: JCB Dieselmax 444",
      "Net Power: Approximately 74 kW (100 hp)",
      "Maximum Lift Height: 11 meters",
      "Maximum Lift Capacity: 3,000 kg",
      "Hydraulic Flow: 110 l/min",
      "Operating Weight: 7,300 kg",
    ],
        "specifications":null,
        "overview": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        "performanceCapabilities": 'The 530-110 Agri combines power, efficiency, and versatility, making it suitable for a wide range of tasks. Its robust build ensures durability even in demanding environments, while its precise hydraulic system allows smooth control for efficient material handling. This telehandler is designed to optimize productivity and reduce operational costs.',
        "noOfPastOwners": null,
        "timeToSell": null,
    },
    {
        "equipmentId": 2074365,
        "title": "TATA HITACHI EX220LC",
        "brandId": null,
        "modelId": null,
        "usedDays": null,
        "askingPrice": 70000.00,
        "gstIncluded": null,
        "finalPrice": 0.00,
        "agentId": null,
        "agentName": "Pawan/ Anuj",
        "agentContactNum": "",
        "commission": null,
        "equipmentType": "Excavator",
        "brandName": "TATA HITACHI",
        "modelName": "EX220LC",
        "yearOfManufacture": "2024",
        "yearOfRegistration": "",
        "hoursMeterReading": "1",
        "custDescription": "",
        "currentLocationState": "MH",
        "currentLocationCity": "",
        "equipmentCondition": "",
        "isLoanExists": "No",
        "interestedForRent": "Yes",
        "vinNum": "",
        "chassisNum": "",
        "engineNum": "",
        "registartionValidate": null,
        "serialno": "",
        "vinImage": null,
        "registeredState": "",
        "ownersCount": null,
        "lotId": null,
        "inDemand": null,
        "isBlockedByYantra": "",
        "isValidInsurance": null,
        "insurance_validity": null,
        "isMachineInspected": null,
        "machineStatus": "Submitted",
        "machineCategoryIntons": null,
        "qualification": null,
        "ownerName": "",
        "ownerId": null,
        "ownerContactNum": "",
        "lastUpdateTs": null,
        "lastUpdateBy": null,
        "createdTs": "2024-09-20T08:07:13.000+00:00",
        "createdBy": "undefined undefined",
        "updatedTs": "2024-09-20T08:08:36.000+00:00",
        "updatedBy": "undefined undefined",
        "pricetoquote": 0,
        "loanexist": "0",
        "okToRent": "0",
        "processStage": null,
        "location": null,
        "customerId": null,
        "customerName": null,
        "bankName": "",
        "additionalInfoStatus": null,
        "additionalInfoProvided": null,
        "imageload": [
            {
                "imageId": 2074379,
                "equipmentId": 2074365,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-20_at_12.56.10_PM_(3)1726819648632.jpeg",
                "imageType": "front view",
                "priority": -1,
                "tags": "Hero Image",
                "comments": null
            },
            {
                "imageId": 2074377,
                "equipmentId": 2074365,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-20_at_12.56.10_PM_(1)1726819648632.jpeg",
                "imageType": "front view",
                "priority": 0,
                "tags": "Right View",
                "comments": null
            },
            {
                "imageId": 2074378,
                "equipmentId": 2074365,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-20_at_12.56.10_PM_(2)1726819648632.jpeg",
                "imageType": "front view",
                "priority": 1,
                "tags": "Left View",
                "comments": null
            },
            {
                "imageId": 2074380,
                "equipmentId": 2074365,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-20_at_12.56.10_PM1726819648632.jpeg",
                "imageType": "front view",
                "priority": 3,
                "tags": "Front View",
                "comments": null
            },
            {
                "imageId": 2074381,
                "equipmentId": 2074365,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-20_at_12.56.11_PM_(1)1726819648632.jpeg",
                "imageType": "front view",
                "priority": 4,
                "tags": "Left View",
                "comments": null
            },
            {
                "imageId": 2074382,
                "equipmentId": 2074365,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Image_2024-09-20_at_12.56.11_PM1726819648632.jpeg",
                "imageType": "front view",
                "priority": 5,
                "tags": "Back View",
                "comments": null
            }
        ],
        "totalCount": 1060,
        "classification": null,
        "comments": null,
        "readyToSellStatus": "",
        "salesStatus": "",
        "salesStatusReason": "",
        "woocommerId": null,
        "woocomStatus": null,
        "websiteLink": null,
        "assetOwnership": "",
        "assetAssignment": "",
        "usedEquipmentFeatures": [],
        "usedEquipmentAttachments": [],
        "zohoItemId": "409173000067123112",
        "quotationStatus": null,
        "quotationPrice": null,
        "quotationId": null,
        "customerTargetPrice": null,
        "buyQuotationStatus": null,
        "buyQuotationPrice": null,
        "buyQuotationId": null,
        "buyAskingPrice": null,
        "sku": "EAI2074365",
        "source": "web",
        "usedOrNew": false,
        "gstType": null,
        "sellingPrice": null,
        "usedEquipmentPriority": null,
        "condition": "",
        "availablilty": "",
        "fuelType": "",
        "insurance": "",
        "inspection": "",
        "sellTerritory": [
            "IND"
        ],
    "specificationsSummary": [
      "Engine: JCB Dieselmax 444",
      "Net Power: Approximately 74 kW (100 hp)",
      "Maximum Lift Height: 11 meters",
      "Maximum Lift Capacity: 3,000 kg",
      "Hydraulic Flow: 110 l/min",
      "Operating Weight: 7,300 kg",
    ],
        "specifications":null,
        "overview": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        "performanceCapabilities": 'The 530-110 Agri combines power, efficiency, and versatility, making it suitable for a wide range of tasks. Its robust build ensures durability even in demanding environments, while its precise hydraulic system allows smooth control for efficient material handling. This telehandler is designed to optimize productivity and reduce operational costs.',
        "noOfPastOwners": null,
        "timeToSell": null,
    },
    {
        "equipmentId": 2072588,
        "title": "JS NXT140",
        "brandId": null,
        "modelId": null,
        "usedDays": null,
        "askingPrice": 3000000.00,
        "gstIncluded": null,
        "finalPrice": 0.00,
        "agentId": null,
        "agentName": "Khileswar Ameta",
        "agentContactNum": "9167344114",
        "commission": null,
        "equipmentType": "Excavator",
        "brandName": "JCB",
        "modelName": "JS NXT140",
        "yearOfManufacture": "2021",
        "yearOfRegistration": "",
        "hoursMeterReading": "5000",
        "custDescription": "",
        "currentLocationState": "GJ",
        "currentLocationCity": "Bharuch",
        "equipmentCondition": "Good working condition",
        "isLoanExists": "No",
        "interestedForRent": "Yes",
        "vinNum": "",
        "chassisNum": "",
        "engineNum": "",
        "registartionValidate": null,
        "serialno": "",
        "vinImage": null,
        "registeredState": "",
        "ownersCount": null,
        "lotId": null,
        "inDemand": null,
        "isBlockedByYantra": "",
        "isValidInsurance": null,
        "insurance_validity": null,
        "isMachineInspected": null,
        "machineStatus": "Submitted",
        "machineCategoryIntons": null,
        "qualification": null,
        "ownerName": "Soumen Ghosh",
        "ownerId": null,
        "ownerContactNum": "",
        "lastUpdateTs": null,
        "lastUpdateBy": null,
        "createdTs": "2024-09-19T07:57:43.000+00:00",
        "createdBy": "Soumen Ghosh",
        "updatedTs": "2024-09-20T07:46:35.000+00:00",
        "updatedBy": "Soumen Ghosh",
        "pricetoquote": 0,
        "loanexist": "0",
        "okToRent": "0",
        "processStage": null,
        "location": null,
        "customerId": null,
        "customerName": null,
        "bankName": "",
        "additionalInfoStatus": null,
        "additionalInfoProvided": null,
        "imageload": [
            {
                "imageId": 2072600,
                "equipmentId": 2072588,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/11726732684578.jpg",
                "imageType": "front view",
                "priority": 0,
                "tags": "Left View",
                "comments": null
            },
            {
                "imageId": 2072601,
                "equipmentId": 2072588,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/21726732684578.jpg",
                "imageType": "front view",
                "priority": 1,
                "tags": "Front View",
                "comments": null
            },
            {
                "imageId": 2074363,
                "equipmentId": 2072588,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/91726818395253.jpg",
                "imageType": "front view",
                "priority": 1,
                "tags": "",
                "comments": null
            },
            {
                "imageId": 2072645,
                "equipmentId": 2072588,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Video_2024-09-19_at_12.16.25_PM1726734020176.mp4",
                "imageType": "front view",
                "priority": 2,
                "tags": "",
                "comments": null
            },
            {
                "imageId": 2072646,
                "equipmentId": 2072588,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/WhatsApp_Video_2024-09-19_at_12.16.26_PM1726734020176.mp4",
                "imageType": "front view",
                "priority": 3,
                "tags": "",
                "comments": null
            },
            {
                "imageId": 2072602,
                "equipmentId": 2072588,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/31726732684578.jpg",
                "imageType": "front view",
                "priority": 4,
                "tags": "Back View",
                "comments": null
            },
            {
                "imageId": 2072603,
                "equipmentId": 2072588,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/41726732684578.jpg",
                "imageType": "front view",
                "priority": 5,
                "tags": "Left View",
                "comments": null
            },
            {
                "imageId": 2072604,
                "equipmentId": 2072588,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/51726732684578.jpg",
                "imageType": "front view",
                "priority": 6,
                "tags": "Bucket",
                "comments": null
            },
            {
                "imageId": 2072605,
                "equipmentId": 2072588,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/61726732684578.jpg",
                "imageType": "front view",
                "priority": 7,
                "tags": "Front View",
                "comments": null
            },
            {
                "imageId": 2072606,
                "equipmentId": 2072588,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/71726732684578.jpg",
                "imageType": "front view",
                "priority": 8,
                "tags": "Right View",
                "comments": null
            },
            {
                "imageId": 2072607,
                "equipmentId": 2072588,
                "imageName": "https://stg-used-equipments.s3.ap-south-1.amazonaws.com/81726732684578.jpg",
                "imageType": "front view",
                "priority": 9,
                "tags": "Right View",
                "comments": null
            }
        ],
        "totalCount": 1060,
        "classification": null,
        "comments": null,
        "readyToSellStatus": "Ready To Sell",
        "salesStatus": "",
        "salesStatusReason": "",
        "woocommerId": null,
        "woocomStatus": null,
        "websiteLink": null,
        "assetOwnership": "",
        "assetAssignment": "",
        "usedEquipmentFeatures": [],
        "usedEquipmentAttachments": [],
        "zohoItemId": null,
        "quotationStatus": null,
        "quotationPrice": null,
        "quotationId": null,
        "customerTargetPrice": null,
        "buyQuotationStatus": null,
        "buyQuotationPrice": null,
        "buyQuotationId": null,
        "buyAskingPrice": null,
        "sku": null,
        "source": "web",
        "usedOrNew": false,
        "gstType": null,
        "sellingPrice": null,
        "usedEquipmentPriority": 300,
        "condition": "USED",
        "availablilty": "",
        "fuelType": "DIESEL",
        "insurance": "YES",
        "inspection": "",
        "sellTerritory": [
            "IND"
        ],
        "specificationsSummary": [
      "Engine: JCB Dieselmax 444",
      "Net Power: Approximately 74 kW (100 hp)",
      "Maximum Lift Height: 11 meters",
      "Maximum Lift Capacity: 3,000 kg",
      "Hydraulic Flow: 110 l/min",
      "Operating Weight: 7,300 kg",
    ],
        "specifications":null,
        "overview": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        "performanceCapabilities": 'The 530-110 Agri combines power, efficiency, and versatility, making it suitable for a wide range of tasks. Its robust build ensures durability even in demanding environments, while its precise hydraulic system allows smooth control for efficient material handling. This telehandler is designed to optimize productivity and reduce operational costs.',
        "noOfPastOwners": null,
        "timeToSell": null
    }
]
export default data;